export default {
  sign_up: 'Registrera dig',
  create_x_account: 'Skapa ett {name}-konto',
  have_an_account: 'Har du ett konto?',
  back_to_home: 'Tillbaka till startsidan',
  terms: 'villkor',
  back: 'Tillbaka',
  disagree: 'inte överens',
  i_agree: 'jag håller med',
  i_have_read_the: 'Jag har läst',
  terms_and_conditions: 'villkor och bestämmelser',
  create_account: 'Skapa konto',
  general_error: 'Allmänt fel',
  sign_in: 'Logga in',
  sign_in_on_the_internal_platform: 'Logga in på affiliatemanagementet',
  create_new_account: 'Skapa nytt konto',
  forgot_password: 'Glömt lösenord',
  advertisers: 'Annonsörer',
  advertiser: 'Annonsör',
  home: 'Hem',
  dashboard: 'Instrumentpanel',
  management: 'Hantering',
  export: 'Exportera',
  import: 'Importera',
  all_advertisers: 'Alla annonsörer',
  new_advertiser: 'Ny annonsör',
  add_advertiser: 'Lägg till annonsör',
  settings_updated_successfully: 'Inställningarna uppdaterades framgångsrikt',
  save_changes: 'Spara ändringar',
  save: 'Spara',
  change_email: 'Byt e-post',
  email_address: 'E-postadress',
  confirm_email_address: 'Bekräfta e-postadress',
  last_name: 'Efternamn',
  first_name: 'Förnamn',
  profile: 'Profil',
  change_password: 'Byt lösenord',
  password_confirmation: 'Bekräfta lösenord',
  password: 'Lösenord',
  min_pass_length_message:
    'Du har angett ett ogiltigt lösenord. Använd 10-20 tecken.',
  password_required: 'Lösenord krävs',
  enter_valid_email: 'Måste vara en giltig e-postadress',
  email_required: 'E-post krävs',
  invalid_password_message: 'Ogiltigt lösenord. Ange ett giltigt lösenord',
  invalid_format: 'Ogiltigt format, följ exemplet',
  invalid_email_message: 'Ogiltig e-post',
  log_in: 'Logga in',
  first_name_validation:
    'Förnamn kan bara innehålla bokstäver (a-z), siffror (0-9) och punkter (.) [med tecken efter] och vara 2-20 tecken långt',
  last_name_validation:
    'Efternamn kan bara innehålla bokstäver (a-z), siffror (0-9) och punkter (.) [med tecken efter] och upp till 20 tecken långt',
  submit: 'Skicka',
  emails_must_match: 'Båda e-postadresserna måste vara likadana',
  campaign: 'Kampanj/Erbjudande',
  campaigns: 'Kampanjer/Erbjudanden',
  all_campaigns: 'Alla kampanjer',
  new_campaign: 'Ny kampanj',
  add_campaign: 'Lägg till kampanj',
  all: 'Alla',
  title: 'Titel',
  description: 'Beskrivning',
  status: 'Status',
  fileStatus: 'Filstatus',
  privacy: 'Integritet',
  privacy_policy: 'Integritetspolicy',
  active: 'Aktiv',
  disable: 'Inaktivera',
  disabled: 'Inaktiverad',
  paused: 'Pausad',
  public: 'Offentlig',
  pre_moderation: 'Förmoderation',
  private: 'Privat',
  no_results_found: 'Inga resultat hittades',
  this_field_is_required: 'Detta fält krävs',
  actions: 'Åtgärder',
  general: 'Allmänt',
  tracking: 'Spårning',
  traffic_source: 'Trafikkälla',
  traffic_sources: 'Trafikkällor',
  currency: 'Valuta',
  features: 'Funktioner',
  featured: 'Utvald',
  start_date: 'Startdatum',
  end_date: 'Slutdatum',
  hold_time: 'Hålltid',
  successfully_updated: 'Uppdaterat framgångsrikt',
  successfully_created: 'Skapat framgångsrikt',
  last_update_newest_first: 'Senaste uppdatering (nyast först)',
  last_update: 'Senaste uppdatering',
  last_update_oldest_first: 'Senaste uppdatering (äldst först)',
  total_orders_high_to_low: 'Totalt antal beställningar (högst till lägst)',
  total_orders_low_to_high: 'Totalt antal beställningar (lägst till högst)',
  delete: 'Ta bort',
  edit: 'Redigera',
  name: 'Namn',
  website: 'Webbplats',
  contact_person: 'Kontaktperson',
  im_or_skype: 'IM / Skype',
  full_name: 'Fullständigt namn',
  address: 'Adress',
  address1: 'Adress 1',
  address2: 'Adress 2',
  postcode: 'Postnummer',
  allowed_ips: 'Tillåtna IP-adresser',
  note: 'Anteckning',
  secure_postback_code: 'Säker återkopplingskod',
  vat_code: 'Momsnummer',
  zipcode: 'Postnummer',
  true: 'Sant',
  false: 'Falskt',
  update_advertiser: 'Uppdatera annonsör',
  create_advertiser: 'Skapa annonsör',
  edit_advertiser: 'Redigera annonsör',
  update_campaign: 'Uppdatera kampanj',
  create_campaign: 'Skapa kampanj',
  settings: 'Inställningar',
  create_traffic_source: 'Skapa trafikkälla',
  update_traffic_source: 'Uppdatera trafikkälla',
  edit_traffic_source: 'Redigera trafikkälla',
  add_traffic_source: 'Lägg till trafikkälla',
  create: 'Skapa',
  update: 'Uppdatera',
  traffic_source_created: 'Trafikkälla skapad',
  new_company_traffic_source: 'Ny företags trafikkälla',
  new_traffic_source: 'Ny trafikkälla',
  edit_campaign: 'Redigera kampanj',
  tracking_url: 'Spårnings-URL',
  preview_url: 'Förhandsvisnings-URL',
  redirect_type: 'Omdirigerings typ',
  traffic_back_url: 'Trafik-back-URL',
  no_advertisers_found: 'Inga annonsörer hittades',
  no_campaign_create_advertiser:
    'För att skapa en kampanj, skapa först en annonsör',
  create_traffic_source_first:
    'För att skapa en kampanj behöver du först en trafikkälla',
  discounted_prices: 'Rabatterade priser',
  email_verified: 'E-post verifierad',
  unique_ip_only: 'Endast unik IP är obligatorisk',
  one_of_ips_invalid:
    'Ogiltigt IP-adressformat: Ange en giltig IP-adress i rätt format (t.ex. 192.168.1.1). Se till att varje sektion är ett nummer mellan 0 och 255 och separeras med punkter.',
  s2s_postback: 'S2S (Återkoppling)',
  probabilistic_attribution: 'Sannolik attribution',
  image: 'Bild',
  js: 'JavaScript',
  iframe: 'Iframe',
  next: 'Nästa',
  ip_is_invalid: 'IP är ogiltig',
  payout: 'Utbetalning',
  payouts: 'Utbetalningar',
  revenue: 'Intäkt',
  goal_value: 'Mål värde',
  goal_title: 'Mål titel',
  payment_type: 'Betalningstyp',
  countries: 'Länder',
  invalid: 'Ogiltig',
  general_settings_title: 'Allmän inställningstitel',
  general_settings_start_date: 'Allmän inställning startdatum',
  general_settings_end_date: 'Allmän inställning slutdatum',
  general_settings_hold_time: 'Allmän inställning hålltid',
  tracking_settings_integration_s2s_postback:
    'Spårningsinställningar Integration S2S Återkoppling',
  tracking_settings_traffic_back_url: 'Spårningsinställningar Trafik-back-URL',
  tracking_settings_preview_url: 'Spårningsinställningar Förhandsvisnings-URL',
  tracking_settings_tracking_url: 'Spårningsinställningar Spårnings-URL',
  payouts_settings_goal_title: 'Utbetalningsinställningar Mål titel',
  payouts_settings_goal_value: 'Utbetalningsinställningar Mål värde',
  payouts_settings_revenue: 'Utbetalningsinställningar Intäkt',
  payouts_settings_payouts: 'Utbetalningsinställningar',
  field: 'Fält',
  ip_should_be_unique: 'IP bör vara unik',
  required: 'Obligatoriskt',
  field_invalid: 'Ogiltigt fält',
  general_payout: 'Allmän utbetalning',
  personal_payout: 'Personlig utbetalning',
  valid: 'Giltig',
  resource_name_unavailable: 'Resursnamn otillgängligt',
  cancel: 'Avbryt',
  end_date_is_invalid: 'Slutdatum är ogiltigt',
  affiliates: 'Affiliates',
  affiliate: 'Affiliate',
  click_session_life_span: 'Klick-sessionens livslängd',
  security: 'Säkerhet',
  password_confirm: 'Bekräfta lösenord',
  current_password: 'Nuvarande lösenord',
  name_unavailable: 'Namn otillgängligt',
  statistics: 'Statistik',
  payouts_soon: 'Utbetalningar (Snart)',
  conversions_soon: 'Konverteringar (Snart)',
  affiliates_soon: 'Affiliates (Snart)',
  campaign_stats: 'Rapporter',
  campaign_id: 'Kampanj-ID',
  campaign_name: 'Kampanjnamn',
  hosts: 'Värdar',
  hits: 'Träffar',
  approved: 'Godkänd',
  approve_affiliate: 'Godkänn affiliate',
  reject_affiliate: 'Avvisa affiliate',
  disapproved: 'Avslagen',
  pending: 'Väntande',
  declined: 'Avslagen',
  decline: 'Avslå',
  cr: 'CR',
  epc: 'EPC',
  traffic: 'Trafik',
  conversions: 'Konverteringar',
  finances: 'Finanser',
  filters: 'Filter',
  hold: 'Håll',
  total: 'Totalt',
  qty: 'QTY',
  null: 'NULL',
  trafficback: 'Trafik tillbaka',
  earnings: 'Totala intäkter',
  ecpm: 'ECPM',
  clear_filters: 'Rensa filter',
  not_selected: 'Ej vald',
  filter: 'Filtrera',
  conversion_id: 'Konverterings-ID',
  comment: 'Kommentar',
  user_agent: 'Användaragent',
  sub_id: 'Sub-ID',
  sub_id_text: 'Sub-ID-text',
  sub_1: 'sub1',
  sub_2: 'sub2',
  sub_3: 'sub3',
  sub_4: 'sub4',
  sub_5: 'sub5',
  sub_6: 'sub6',
  sub_7: 'sub7',
  sub_8: 'sub8',
  sub1: 'Sub 1',
  sub2: 'Sub 2',
  sub3: 'Sub 3',
  sub4: 'Sub 4',
  sub5: 'Sub 5',
  sub6: 'Sub 6',
  sub7: 'Sub 7',
  sub8: 'Sub 8',
  goal_name: 'Målnamn',
  time_period: 'Tidsperiod',
  income: 'Inkomst',
  device: 'Enhet',
  geography_ip: 'Geografi / IP',
  amount: 'Belopp',
  date: 'Datum',
  today: 'IDAG',
  yesterday: 'IGÅR',
  last_week: 'FÖRRA VECKAN',
  last_two_weeks: 'SENASTE 2 VECKORNA',
  last_month: 'SENASTE MÅNADEN',
  last_three_months: 'SENASTE 3 MÅNADERNA',
  last_six_months: 'SENASTE 6 MÅNADERNA',
  last_year: 'SENASTE ÅRET',
  custom: 'ANPASSAD',
  conversion_stats: 'Konverteringsstatistik',
  create_conversion: 'Skapa konvertering',
  update_conversion: 'Uppdatera konvertering',
  edit_conversion: 'Redigera konvertering',
  all_conversions: 'Alla konverteringar',
  add_conversion: 'Lägg till konvertering',
  conversion_created: 'Konvertering skapad',
  conversion_updated: 'Konvertering uppdaterad',
  currently_unavailable: 'För närvarande otillgänglig',
  creation_date: 'Skapelsedatum',
  all_payouts: 'Alla utbetalningar',
  add_payout: 'Lägg till utbetalning',
  add: 'Lägg till',
  create_payout: 'Skapa utbetalning',
  denied: 'Nekad',
  number: 'Nummer',
  created_at: 'Skapad den',
  approved_and_paid: 'Godkänd och betald',
  order_id: 'Order-ID',
  include_affiliate: 'Inkludera affiliate',
  amount_to_be_approved: 'Belopp att godkänna',
  commission_to_be_approved: 'Provision att godkänna',
  edit_payout: 'Redigera utbetalning',
  advertiser_name: 'Annonsörsnamn',
  total_amount: 'Totalt belopp',
  campaign_start_date: 'Kampanj startdatum',
  campaign_end_date: 'Kampanj slutdatum',
  update_payout: 'Uppdatera utbetalning',
  total_commission: 'Total provision',
  download_report: 'Ladda ner rapport',
  file_uploaded_successfully: 'Fil laddad upp framgångsrikt',
  download_latest_report: 'Ladda ner senaste rapporten',
  download_original_report: 'Ladda ner originalrapporten',
  payout_updated: 'Utbetalning uppdaterad',
  include_commission: 'Inkludera provision',
  no_report_attached: 'Ingen rapport bifogad',
  report_is_valid: 'Rapporten är giltig',
  view_payout: 'Visa utbetalning',
  affiliates_requests: 'Affiliates förfrågningar',
  requests: 'Förfrågningar',
  approve: 'Godkänn',
  affiliate_payouts: 'Affiliate-utbetalningar',
  save_and_next: 'Spara och nästa',
  available_to_payout: 'Tillgängligt för utbetalning',
  request_payout: 'Begär utbetalning',
  commission: 'Provision',
  affiliate_commission: 'Affiliate-provision',
  see_all: 'Se alla',
  clicks: 'Klick',
  more_details: 'Mer detaljer',
  email: 'E-post',
  affiliate_website: 'Affiliate-webbplats',
  disapprove: 'Avvisa',
  change_to_approved: 'Ändra till godkänd',
  change_to_declined: 'Ändra till avslagen',
  contact_details: 'Kontaktuppgifter',
  login_as_affiliate: 'Logga in som affiliate',
  login: 'Logga in',
  add_affiliate: 'Lägg till affiliate',
  create_affiliate: 'Skapa affiliate',
  edit_affiliate: 'Redigera affiliate',
  try_again_later: 'Försök igen senare',
  added_affiliate: 'Tillagd affiliate',
  affiliate_management: 'Affiliate-hantering',
  merchant_commission: 'Provision',
  phone_number: 'Telefonnummer',
  affiliate_updated: 'Affiliate uppdaterad',
  affiliate_created: 'Affiliate skapad',
  merchant_management: 'Hantering',
  user_management: 'Användarhantering',
  identity: 'Identitet',
  smtp: 'SMTP',
  edit_merchant: 'Redigera användarhantering',
  merchant_updated: 'Användarhantering uppdaterad',
  add_merchant: 'Lägg till användarhantering',
  create_merchant: 'Skapa användarhantering',
  company_name: 'Affiliate',
  company_name_for_invoice: 'Företagsnamn',
  update_merchant: 'Uppdatera användare',
  campaign_logo: 'Kampanjlogotyp',
  io_document: 'IO-dokument',
  select_logo: 'Välj logotyp',
  select_io_document: 'Välj IO-dokument',
  img: 'Bild',
  picture_unavailable: 'Bild otillgänglig',
  logo: 'Logotyp',
  id: 'ID',
  select_campaign: 'Välj kampanj',
  js_landing_page: 'Javascript Landningssida',
  js_thank_you: 'Js Tack',
  js_landing_page_text:
    'Annonsören bör placera denna kod efter öppning <body>-taggen på landningssidan. Det krävs att lägga till parameter &click_id={click_id} i spårnings-URL:en.',
  js_thank_you_text:
    'Annonsören bör placera denna kod efter öppning <body>-taggen på framgångssidan "Tack".',
  end_date_before_start_date: 'Slutdatum är före startdatum',
  login_email: 'Logga in E-post',
  login_password: 'Logga in Lösenord',
  server: 'Server',
  port: 'Port',
  protocol: 'Protokoll',
  smtp_and_ssl: 'SMTP + SSL',
  smtp_and_tls: 'SMTP + TLS',
  created: 'Skapad',
  updated: 'Uppdaterad',
  forgot_password_message:
    'Ange din e-postadress nedan för att återställa ditt lösenord.',
  search_affiliate: 'Sök affiliate',
  search_advertisers: 'Sök annonsörer',
  search_campaigns: 'Sök kampanjer',
  search_payouts: 'Sök utbetalningar',
  search_conversions: 'Sök konverteringar',
  search_campaign_request: 'Sök kampanjförfrågan',
  custom_domain: 'SSL-anpassad domän',
  branding: 'Varumärke',
  request_details: 'Förfrågningens detaljer',
  search_traffic_source: 'Sök trafikkälla',
  performance_chart: 'Prestanda',
  last_conversions: 'Senaste konverteringar',
  last_30_days: 'SENASTE 30 DAGARNA',
  reports: 'Rapporter',
  documents: 'Dokument',
  search: 'Sök',
  SUB1: 'SUB1',
  SUB2: 'SUB2',
  SUB3: 'SUB3',
  SUB4: 'SUB4',
  SUB5: 'SUB5',
  SUB6: 'SUB6',
  SUB7: 'SUB7',
  SUB8: 'SUB8',
  top_campaigns: 'Top kampanjer',
  Paused: 'Pausad',
  Disabled: 'Avaktiverad',
  logout: 'Logga ut',
  Active: 'Aktiv',
  click_session_days: 'Klicksessionens dagar',
  click_session_hours: 'Klicksessionens timmar',
  statuses: 'Statusar',
  enter_new_password: 'Ange nytt lösenord',
  download: 'Ladda ner',
  not_active: 'Inte aktiv',
  activate: 'Aktivera',
  test_email: 'Skicka test-e-post',
  alias: 'Alias',
  smtp_connection_failed_change_details:
    'SMTP-anslutningen misslyckades, överväg att ändra inställningarna.',
  send_test_mail: 'Testmeddelande skickat',
  smtp_connected_successfully: 'SMTP ansluten framgångsrikt',
  mail: 'E-post',
  header: 'Header',
  footer: 'Footer',
  attribution: 'Attribution',
  first_click: 'Första klicket',
  last_click: 'Sista klicket',
  earn_per_click: 'Förtjäna per klick',
  click_rate: 'Klickfrekvens',
  campaign_details: 'Kampanjdetaljer',
  disallowed: 'Inte tillåtet',
  preview: 'Förhandsgranska',
  hint: 'Hint',
  coupon: 'Kupong',
  cname_missing_add_to_dns_manager:
    'CNAME saknas, lägg till CNAME i din DNS-hanterare.',
  new_affiliate_needs_activation: 'Ny affiliate e-postverifiering',
  new_affiliate_needs_manual_activation:
    'Ny affiliate behöver manuell aktivering',
  change_manager: 'Byt chef',
  manager: 'Chef',
  default_merchant: 'Standard Merchant',
  merchant: 'Merchant',
  need_help: 'Behöver hjälp',
  check_our_docs: 'Kolla in våra dokument',
  documentation: 'Dokumentation',
  activation: 'Aktivering',
  changed_email: 'Ändrad e-post',
  reset_password: 'Återställ lösenord',
  invitation: 'Inbjudan',
  password_changed: 'Lösenordet har ändrats',
  enter_email: 'Ange e-post',
  industry: 'Bransch',
  and: 'och',
  welcome: 'Aktiverings-e-post',
  available_macros: 'Tillgängliga makron',
  custom_fields: 'Anpassade fält',
  add_custom_field: 'Lägg till anpassat fält',
  search_custom_field: 'Sök anpassat fält',
  edit_custom_field: 'Redigera anpassat fält',
  create_custom_field: 'Skapa anpassat fält',
  update_custom_field: 'Uppdatera anpassat fält',
  add_custom_doc: 'Lägg till anpassat dokument',
  search_custom_doc: 'Sök anpassat dokument',
  edit_custom_doc: 'Redigera anpassat dokument',
  create_custom_doc: 'Skapa anpassat dokument',
  update_custom_doc: 'Uppdatera anpassat dokument',
  custom_field_name: 'Namn på anpassat fält',
  not_required: 'Inte nödvändigt',
  field_name_is_already_used: 'Fältnamnet används redan',
  are_you_sure: 'Är du säker?',
  are_you_sure_you_want_to_delete: 'Är du säker på att du vill ta bort?',
  text_area: 'Textområde',
  check_box: 'Kryssruta',
  input: 'Inmatning',
  type: 'Typ',
  field_type: 'Fälttyp',
  default_manager: 'Standardchef',
  brand_logo: 'Företagslogotyp',
  reached_max_campaigns_limit: 'Den maximala kampanjgränsen har nåtts',
  billing: 'Fakturering',
  payments: 'Betalningar',
  plan: 'Plan',
  paypal: 'PayPal',
  wire_transfer: 'Banköverföring',
  payout_source: 'Utbetalningskälla',
  price: 'Pris',
  transaction_id: 'Transaktions-ID',
  view_invoice: 'Visa faktura',
  upgrade: 'Uppgradera',
  work_email: 'Arbets-e-post',
  message: 'Meddelande',
  contact_us: 'Kontakta oss',
  we_will_contact_u_soon: 'Vi kommer att kontakta dig snart!',
  terms_and_conditions_and_privacy_policy: 'Villkor och sekretesspolicy',
  current_email: 'Nuvarande e-post',
  please_update_your_plan: 'Uppdatera din plan',
  here: 'här',
  custom_domain_updated: 'Anpassad domän uppdaterad',
  your_email: 'Din e-post',
  allowed: 'Tillåtet',
  created_merchant: 'Skapad merchant',
  affiliate_company: 'Affiliateföretag',
  linked_to_payout: 'Länkad till utbetalning',
  subject: 'Ämne',
  has_payout: 'Har utbetalning',
  yes: 'Ja',
  no: 'Nej',
  select: 'Välj',
  view_conversions: 'Visa konverteringar',
  search_conversions_by_campaign_affiliate_or_payout:
    'Sök efter kampanjnamn eller affiliateföretagsnamn.',
  traffic_back_explanation:
    'Klienten kommer att omdirigeras till "Traffic Back URL" vid fel eller om kampanjen är inaktiverad.',
  goal_value_explanation_row_1:
    'Målbeloppet ger möjlighet att betala för händelser eller kategorier.',
  goal_value_explanation_row_2:
    "Det 'Målbelopp' måste returneras av din kod/postback.",
  goal_value_explanation_row_3: `För mer information <a href="https://help.tracknow.io/knowledge-base/create-goals/">Klicka här</a>`,
  test_smtp_email: 'Testa SMTP-email',
  campaign_terms: 'Kampanjvillkor',
  new_email: 'Ny e-post',
  payment_details: 'Betalningsdetaljer',
  request_date: 'Begärande datum',
  reason: 'Orsak',
  affiliate_request: 'Affiliate-begäran',
  the_reason_will_be_visible_to_the_affiliate:
    'Orsaken kommer att vara synlig för affiliaten',
  affiliate_registration_url: 'Affiliate-registrerings-URL',
  copy: 'Kopiera',
  copied_to_clipboard: 'Kopierad till Urklipp',
  minimum_withdrawal_amount: 'Minsta uttagsbelopp',
  payment_methods: 'Betalningsmetoder',
  add_payment_method: 'Lägg till betalningsmetod',
  search_payment_method: 'Sök betalningsmetod',
  create_payment_method: 'Skapa betalningsmetod',
  edit_payment_method: 'Redigera betalningsmetod',
  payment_method_created: 'Betalningsmetod skapad',
  payment_method: 'Betalningsmetod',
  conversion_statuses_explanation:
    "** För att skapa en utbetalning till Excel och ändra konverteringsstatus med en stor uppdateringsomgång, välj annonsören och datum och klicka på 'SKAPA UTTAG.'",
  powered_by_tracknow: 'Drivs av Tracknow',
  change_email_confirmation_email_sent:
    'En bekräftelsemejl har skickats till din nuvarande e-post',
  check_your_data: 'Fel',
  are_you_sure_you_want_to_create_a_payout:
    'Är du säker på att du vill skapa en utbetalning',
  create_payout_confirmation: 'Skapa utbetalningsbekräftelse',
  payout_statuses_explanation:
    '* Utbetalningsstatus: Godkänd - utbetalning tillgänglig för betalning. Pågående - utbetalningsprocessen måste fortfarande slutföras. Avvisad - utbetalningen har avslagits av en särskild anledning.',
  payout_created: 'Utbetalning skapad',
  excel_statuses_explanation:
    'Excel-statusar: Ändra statusarna i Excel-filen och ladda upp Excel till servern. \n\n' +
    '  Använd följande statusar inuti Excel:\n' +
    '  GODKÄND - provisionen kommer att vara tillgänglig för affiliaten. \n' +
    '  PÅGÅENDE - konverteringen kommer att ändras till väntestatus. \n' +
    '  AVVISAD - konverteringen kommer att tas bort från väntande saldo/tillgängligt saldo.\n' +
    '\n' +
    '\n' +
    'Observera att utbetalningsstatus inte påverkar konverteringsstatus, om du vill ändra konverteringsstatus måste du skapa en ny utbetalning från konverteringsskärmen.\n' +
    '\n' +
    '* Observera att konverteringsstatus endast kommer att ändras när utbetalningsstatus ändras till Godkänd och Excel laddas upp.\n' +
    '\n' +
    '* För att ändra konverteringsstatus, ladda upp Excel-filen.',
  system_id: 'System-ID',
  tax: 'Skatt',
  number_must_be_between: 'Numret måste vara mellan {min} och {max}',
  ready: 'Klar',
  edit_statuses: 'Redigera statusar',
  apply_changes_to: 'Tillämpa ändringar på ({count})',
  failed: 'Misslyckades',
  processing: 'Bearbetning',
  affiliate_payout: 'Affiliate-utbetalning',
  permissions: 'Behörigheter',
  read_permissions: 'Läsbehörigheter',
  write_permissions: 'Skrivbehörigheter',
  both_permissions: 'Läs/Skrivbehörigheter',
  enter_new_password_reset:
    'Ange ditt nya lösenord nedan för att återställa ditt lösenord.',
  referer: 'Referer',
  coupon_code: 'Kupongkod',
  country: 'Land',
  please_enable_smtp_first: 'Vänligen aktivera SMTP först',
  scale: 'Skala',
  rotate: 'Rotera',
  crop: 'Beskära',
  time_format: 'Tidsformat',
  time_zone: 'Tidszon',
  billing_description:
    'Upptäck Tracknow affiliate-plattformens faktureringsida. Påbörja din affiliate-marknadsföringsresa gratis med vårt Free-plan. Uppgradera till våra Team- eller Enterprise-planer när du växer och lås upp avancerade funktioner. Flexibla betalningsalternativ finns tillgängliga. Anslut till oss idag och låt kraften hos Tracknow komma till sin rätt!',
  personal_settings: 'Personliga inställningar',
  personal: 'Personligt',
  company_settings: 'Företagsinställningar',
  day: 'Dag',
  year: 'År',
  month: 'Månad',
  group_by: 'Gruppera efter',
  deep_link: 'Djup länk',
  tracking_params: 'Spårningsparametrar',
  order_amount: 'Orderbelopp',
  unlimited_sales: 'Obegränsade försäljningar',
  up_to_x_affiliates: 'Upp till {0} Affiliates',
  ssl_setup: 'SSL-installation',
  dedicated_smtp: 'Hängiven SMTP',
  custom_commissions: 'Anpassade provisioner',
  custom_commission_affiliate: 'Anpassade affiliatprovisioner',
  email_support: 'E-postsupport',
  no_limit_campaigns: 'Obegränsade kampanjer',
  no_limit_affiliates: 'Obegränsade affiliates',
  email_and_chat_support: 'E-post- och chattstöd',
  dedicated_account_manager: 'Hängiven kontoansvarig',
  coupon_offline: 'Kupongspårning',
  invoices: 'Fakturor',
  next_subscription_fee_due: 'Nästa prenumerationsavgift förfaller:',
  you_wiil_return_to_the_free_tier_at:
    'Ditt abonnemang avbröts. Vänligen kontakta vår support på support@tracknow.io',
  are_you_sure_you_want_to_reset_cname:
    'Är du säker på att du vill återställa anpassad domän?',
  reset_cname: 'Återställ anpassad domän',
  subscription_details: 'Prenumerationsdetaljer:',
  max_x_capmaigns: '{0} Domäner/Erjudanden',
  unlimited_campaigns: 'Obegränsade Domäner/Erjudanden',
  tax_not_included: 'Skatt ej inkluderad',
  email_for_invoice: 'E-post för faktura:',
  plans_and_pricing: 'Planer och prissättning',
  reset: 'Återställ',
  your_membership_tier: 'Din medlemsnivå:',
  click_here: 'Klicka här',
  advertiser_should_add_the:
    'Annonsören bör lägga till följande parametrar till URL: order_id, amount, goal, för fler spårningsalternativ, vänligen',
  for_a_tutorial_on_how_to_add_postback:
    'För en handledning om hur du lägger till postback, vänligen',
  payout_settings: 'Utbetalningsinställningar',
  brand_colors: 'Varumärkesfärger',
  custom_script: 'Anpassad Script',
  getting_started: 'Komma igång',
  done: 'Klar',
  deleted_successfully: 'Borttagen framgångsrikt',
  creatives: 'Kreativa',
  add_new: 'Lägg till ny',
  create_a_campaign: 'Skapa en kampanj',
  adding_an_affiliate: 'Lägger till en affiliate',
  affiliate_dashboard: 'Affiliate-Dashboard',
  feel_free_to_contact_us: 'För ytterligare hjälp, vänligen kontakta oss',
  campaign_management: 'Kampanjhantering',
  add_new_crative: 'Lägg till ny kreativ',
  are_you_sure_you_want_to_delete_this_creative: 'Radera',
  delete_notify: 'Är du säker på att du vill radera denna kreativa?',
  creative: 'Kreativ',
  mlm_commission: 'Referrer Provision',
  mlm_level2_commission: '2:a Referrer Provision',
  allow_mlm: 'Tillåt MLM (Multi Level Marketing)',
  mlm: 'Multi Level Marketing (MLM)',
  mlm_percent: 'MLM Procent',
  allow_mlm_level2: 'Tillåt MLM Nivå 2',
  mlm_level2_percent: 'MLM Nivå 2 Procent',
  referrer: 'Referrer',
  default_campaign: 'Standardkampanj',
  conversion_tracking_setup: 'Inställning för konverteringsspårning',
  content: 'Innehåll',
  inactive: 'Inaktiv',
  draft: 'Utkast',
  none: 'Ingen',
  invalid_url: 'Ogiltig URL',
  categories_tracking: 'Kategorispårning',
  phone_support: 'Telefonsupport',
  chat_support: 'chattsupport',
  unlimited_banners: 'Obegränsade kreativa',
  impressions: 'Visningar (CPM)',
  cpm_commissions: 'CPM-provisioner',
  conversion_commissions: 'Konverteringsprovisioner',
  ppc_commissions: 'PPC-provisioner',
  support_app_conversions: 'Stöd för APP (Android/IOS)-konverteringar',
  support_app_helper:
    '* I fall där klick och konverteringspixel kommer från olika agentwebbläsare.',
  requested_commission: 'Önskad provision',
  manage: 'Hantera',
  select_a_payout: 'Välj en utbetalning',
  select_a_campaign: 'Välj en kampanj',
  use_general_capmaign_settings: 'Använd allmänna kampanjinställningar',
  add_user_to_campaign: 'Tilldela Affiliate till kampanj',
  payout_groups: 'Utbetalningsgrupper',
  commissions: 'Provisioner',
  affiliate_details: 'Affiliate-detaljer',
  creative_empty_desc: 'Inga kreativa.',
  country_code_required: 'Land krävs vid registrering',
  custom_docs: 'Anpassade dokument',
  APPROVED: 'Godkänd',
  REJECTED: 'Avvisad',
  PENDING: 'Väntande',
  NEEDS_ATTENTION: 'Behöver uppmärksamhet',
  INCOMPLETE: 'Ofullständig',
  EXPIRED: 'Utgånget',
  update_status: 'Uppdatera status',
  options: 'Alternativ',
  select_helper: 'Separera värdena med ett komma. Exempel: Man, Kvinna.',
  select_files: 'Välj filer',
  drop_files_here_or_click_browse_through_your_machine:
    'Släpp filer här eller klicka för att bläddra genom din maskin',
  remove_all: 'Ta bort alla',
  upload_files: 'Ladda upp filer',
  no_documents_required: 'Inga dokument krävs',
  admin_status_changed_email: 'Administrativ status ändrad',
  admin_status_approved_email: 'Administrativ status godkänd',
  when_company_requires_documents_upload_here:
    'När ett företag kräver dokument måste du ladda upp dem här',
  single_campaign_mode: 'Enkel kampanjläge',
  single_campaign_mode_off: 'Alla kampanjer är tillgängliga.',
  single_campaign_mode_on:
    'Åtkomst begränsas till standardkampanjer (företag eller användarinställda).',
  country_grouping: 'Landgruppering',
  grouping: 'Gruppering',
  create_grouping: 'Skapa grupp',
  symbols: 'Symboler',
  symbol_grouping: 'Symbolgruppering',
  create_symbol: 'Skapa symbol',
  default_status: 'Standardstatus',
  payout_type: 'Utbetalningstyp',
  confirmation: 'Bekräftelse',
  copy_short_url: 'Kopiera kort länk',
  generate_short_url: 'Generera kort länk',
  short_url: 'Kort länk',
  after_activation: 'Välkommen (E-posten skickas endast om brödtexten finns)',
  users: 'Användare',
  forex: 'Finans',
  add_user: 'Lägg till användare',
  edit_user: 'Redigera användare',
  first_deposit_date: 'Datum för första insättning',
  user_id: 'Användar-ID',
  lead_id: 'Lead-ID',
  balance: 'Saldo',
  volume: 'Volym',
  lastAffiliateGroupChangeDate: 'Senaste ändringsdatum för affiliategrupp',
  firstDepositDate: 'Datum för första insättning',
  account_id: 'Konto-ID',
  account_currency: 'Kontovaluta',
  add_account: 'Lägg till konto',
  edit_account: 'Redigera konto',
  add_transaction: 'Lägg till transaktion',
  edit_transaction: 'Redigera transaktion',
  accounts: 'Konton',
  account: 'Konto',
  forex_account: 'Forex-konto',
  profit: 'Vinst',
  symbol: 'Symbol',
  transactions: 'Transaktioner',
  transaction_date: 'Transaktionsdatum',
  open_price: 'Öppningspris',
  close_price: 'Stängningspris',
  show_symbols: 'Visa symboler',
  qualified: 'Kvalificerad',
  leverage: 'Leverage',
  user: 'Användare',
  symbol_payout_modal_title: 'Lägg till pris för symbol',
  symbol_payout_modal_desc: 'Lägg till pris för symbol',
  position_count: 'Antal positioner',
  net_deposit: 'Nettoinsättning',
  ask_price: 'Frågepris',
  bid_price: 'Budpris',
  transaction_type: 'Transaktionstyp',
  broker_commission: 'Mäklarprovision',
  revshare_type: 'Revshare-typ',
  currency_price: 'Valutapris',
  converted_volume: 'Omvandlad volym',
  more_options: 'Fler alternativ',
  financial_users: 'Finansiella användare',
  financial_accounts: 'Finansiella konton',
  first_time_deposit: 'Första insättning',
  new_country_grouping: 'Ny landsgruppering',
  new_symbol_grouping: 'Ny symbolgruppering',
  currency_change_title: 'Varning: Bekräftelse av valutaväxling',
  currency_change_desc:
    'Du håller på att ändra din företagsvaluta. Observera att detta kommer att ändra värdet på din konverteringsvaluta och kan påverka dina kontoinställningar.',
  tax_id: 'Skatt-ID',
  affiliate_settings: 'Affiliateinställningar',
  hideOrderId: 'Dölj Order-ID (Affiliate)',
  hideAmount: 'Dölj Belopp (Affiliate)',
  allow_ip_explain:
    'Endast postbacks från dessa IP-adresser kommer att tillåtas (Vita listade IP-adresser). IP-adresserna ska separeras med ett komma. Exempel: 127.0.0.1, 192.17.17.1. Lämna fältet tomt för att tillåta alla IP-adresser.',
  setup_included: 'Inkluderad installation',
  affiliate_platform_name: 'Affiliateplattformsnamn',
  assign_personal_payouts: 'Tilldela personliga utbetalningar',
  add_notes: 'Lägg till anteckningar',
  author: 'Författare',
  new_note: 'Ny anteckning',
  net_deposits: 'Nettoinsättningar',
  notes: 'Anteckningar',
  referrals: 'Henvisningar',
  screens: 'Skärmar',
  fields: 'Fält',
  financialAccountId: 'Finansiellt kontoid',
  financialTransactionsProfit: 'Vinst för finansiella transaktioner',
  financialTransactionsId: 'ID för finansiella transaktioner',
  financialUserBalance: 'Saldo för finansiell användare',
  view: 'Visa',
  generate_qr_code: 'Generera QR-kod',
  qr_code: 'QR-kod',
  search_by_user_id_or_lead_id: 'Sök efter användar-ID eller lead-ID',
  postback_stats: 'Postback',
  success: 'Framgång',
  fail_reason: 'Orsak',
  click_id: 'Klick-ID',
  logs: 'Loggar',
  ip: 'IP',
  parameters: 'Parametrar',
  top_affiliates: 'Top-affiliates',
  disallowed_countries: 'Ej tillåtna länder',
  changes: 'Ändringar',
  compare: 'Jämför',
  entity_id: 'Entitets-ID',
  entity_class: 'Entitetsklass',
  activity_type: 'Aktivitetstyp',
  audit: 'Granskning',
  info: 'Information',
  company: 'Företag',
  individual: 'Individuell',
  requireAffiliateLegalStatus: 'Kräv affiliate-legalstatus',
  please_verify_you_are_not_a_robot: 'Verifiera att du inte är en robot',
  please_fill_captcha: 'Fyll i captcha nedan:',
  two_factor_auth_dialog_title: 'Begäran om tvåfaktorsautentisering',
  enable: 'Aktivera',
  two_factor_auth_dialog_description:
    'Den aktuella användaren har aktiverat tvåfaktorsautentisering (2FA) för ökad säkerhet. För att fortsätta med den begärda åtgärden, ange engångslösenordet (OTP)',
  '2fa': 'Tvåfaktorsautentisering (2FA)',
  enter_6_digit_code: 'Ange 6-siffrig kod',
  INVALID_2FA_TOKEN: 'Ogiltig 2FA-token',
  MISSING_2FA_TOKEN: 'Saknar 2FA-token',
  payout_automation: 'Automatisering av utbetalningar',
  add_payout_automation: 'Lägg till utbetalningsautomatisering',
  automation_type: 'Automatiseringstyp',
  original_payout_setting: 'Ursprunglig utbetalningsinställning',
  campaign_payout_automation_tiers:
    'Automatiseringstjänster för kampanjutbetalningar',
  payout_tiers: 'Utbetalningstjänster',
  tier_threshold: 'Tröskel för nivå',
  threshold: 'Tröskel',
  campaign_payout_automation_title:
    'Automatiska justeringar av affiliateutbetalningar',
  campaign_payout_automation_subtitle: `Vi har infört en förbättrad funktion för att optimera affiliate-utbetalningar
    baserat på prestanda. Så här fungerar det:`,
  are_you_sure_you_want_to_run_this_automation:
    'Är du säker på att du vill köra automatiseringen?',
  run: 'Kör',
  last_run_date: 'Senaste kördatum',
  financial_stats: 'Finansiell statistik',
  deposits: 'Insättningar',
  withdrawals: 'Uttag',
  registrations: 'Registreringar',
  advanced_tracking: 'Avancerad spårning',
  NO_PERMISSION:
    'Du har inte behörighet att utföra denna åtgärd. Kontakta administratören',
  financial_transactions: 'Finansiella transaktioner',
  click_logs: 'Klickloggar',
  weight: 'Vikt',

  weight_helper:
    'Värdet Vikt representerar sannolikheten; ju högre värdet, desto större är chansen att det visas. Denna funktion används för A/B-testning.',
  direct_link: 'Direktlänk',
  direct_link_title: 'Direktlänk (Installation krävs)',
  direct_link_subtitle:
    'En annonsör bör placera denna kod efter öppnande <body>-taggen på landningssidan. ',
  direct_link_helper:
    'Aktivera detta alternativ för att behålla din spårnings-URL oförändrad för bättre SEO. Observera att med Direktlänk kommer avancerade spårningsfunktioner som makron att vara otillgängliga. För att säkerställa korrekt datainsamling behöver en liten skript implementeras på din webbplats. Växla fritt mellan Direktlänk och traditionella metoder baserat på dina spårningspreferenser. För eventuell hjälp är vårt supportteam redo att hjälpa.',
  dont_show_again: 'Hoppa över och visa inte igen',
  dont_show_again_desc:
    'Genom att välja detta alternativ kommer sektionen Komma igång att döljas från menyn för en mer strömlinjeformad användarupplevelse. Det är viktigt att förstå att du enkelt kan justera alla inställningar genom att besöka sidan Inställningar när du vill, vilket gör det möjligt för dig att anpassa din upplevelse enligt dina preferenser. Om du har några frågor eller behöver hjälp, kontakta gärna vårt engagerade supportteam. \nDin tillfredsställelse är viktig för oss, och vi finns här för att hjälpa dig.',
  campaign_advanced_tracking_title:
    'Introduktion av våra förbättrade funktioner',
  campaign_advanced_tracking_subtitle: `Avancerad spårning och A/B-testning. Upptäck hur dessa funktioner kan gynna dig:`,
  campaign_advanced_tracking_geo: `<strong>Avancerad spårning för geo-targetingstrategi</strong> - Det tillåter dig automatiskt att guida användare från specifika länder till angivna sidor, vilket maximalt riktar sig till publiken. 
  Installationsinstruktioner finns <a href="https://help.tracknow.io/knowledge-base/advanced-tracking/" target=“_blank”>här</a>.`,
  campaign_advanced_tracking_ab: `<strong>A/B-testning </strong>- fatta datadrivna beslut genom att jämföra prestandan för olika webbsidor, olika url eller element genom A/B-testning för att identifiera den bäst presterande varianten. 
  Installationsinstruktioner finns <a href="https://help.tracknow.io/knowledge-base/advanced-tracking-a-b-testing/" target=“_blank”>här</a>.`,
  yearly: 'Årlig',
  monthly: 'Månad',
  x_off: '{discount}% Rabatt',
  compare_plans: 'Jämför planer',
  invalidPasswordMessage:
    'Ditt lösenord måste innehålla minst 1 bokstav, 1 numerisk siffra, 1 specialtecken (!@#$%^&*()_+), och vara mellan 10 och 20 tecken långt.',
  download_sample_CSV: 'Ladda ner prov-CSV',
  import_affiliates: 'Importera affiliates',
  import_affiliates_desc:
    'Problemfri hantering av data: Ladda upp CSV, Redigera fält sömlöst (Dubbelklicka) och Skicka med felhantering för en smidig upplevelse',
  payout_desc: 'Utbetalningsbeskrivning',
  payout_desc_helper:
    'Om utbetalningsbeskrivningen är tom kommer vi att visa utbetalningen baserat på inställningen',
  become_an_advertiser: 'Bli annonsör',
  become_an_advertiser_desc:
    'Vänligen fyll i formuläret nedan för att ansöka om att gå med i vår plattform som annonsör',
  become_an_advertiser_res:
    'Tack för att du skickade din förfrågan om att bli annonsör. När den godkänts kommer du att få inloggningsuppgifter via e-post',
  advertiser_registrations: 'Annonsörsregistreringar',
  allow_affiliate_register: 'Tillåt affiliate-registrering',
  allow_advertiser_register: 'Tillåt annonsörsregistrering',
  advertiser_user_management: 'Annonsörsanvändarhantering',
  advertiser_login: 'Annonsörinloggning',
  advertiser_user_login_desc:
    'Observera att varje åtgärd som vidtas efter inloggning är ditt eget ansvar och kommer att påverka annonsörskontot.',
  edit_advertiser_user: 'Redigera annonsörsanvändare',
  advertiser_user_updated: 'Annonsörsanvändare uppdaterad',
  add_advertiser_user: 'Lägg till annonsörsanvändare',
  create_advertiser_user: 'Skapa annonsörsanvändare',
  custom_merchant_domain: 'SSL anpassad handelsdomän (för annonsörer)',
  login_as_advertiser: 'Logga in som annonsör',
  login_as_admin: 'Logga in som administratör',
  adv_registration_url: 'Annonsörsregistrerings-URL',
  free_trial_ending: 'Prova på-perioden slutar om {0}',
  email_config_activation: 'Aktivering',
  email_config_reset_password: 'Återställning av lösenord',
  email_config_changed_email: 'Ändring av e-post',
  email_config_welcome:
    'Välkommen (E-mailet skickas endast när brödtexten finns)',
  email_config_request_to_join_campaign_approved:
    'Begäran att gå med i kampanjen godkänd',
  email_config_request_to_join_campaign_declined:
    'Begäran att gå med i kampanjen avvisad',
  email_config_admin_status_approved: 'Administratörsstatus godkänd',
  email_config_admin_status_changed: 'Administratörsstatus ändrad',
  email_config_payment_request_approved: 'Betalningsbegäran godkänd',
  email_config_payment_request_declined: 'Betalningsbegäran avvisad',
  profile_pic: 'Profilbild',
  custom_statuses: 'Anpassade statusar',
  add_custom_status: 'Lägg till anpassad status',
  edit_custom_status: 'Redigera anpassad status',
  ip_cooldown: 'IP-kylningstid',
  ip_cooldown_helper:
    'Väntetiden mellan varje konvertering för en given IP-adress.',
  instant: 'Omedelbar',
  one_hour: 'En timme',
  one_day: 'En dag',
  one_week: 'En vecka',
  one_month: 'En månad',
  one_year: 'Ett år',
  free_mode: 'Fritt läge',
  use_a_semicolon:
    'Använd ett semikolon för att lägga till flera valutapar, till exempel: AUDCAD;EURUSD',
  new_symbols_will_be_added:
    'Nya symboler läggs till automatiskt med en pip på 4 (kan ändras senare)',
  group_name: 'Gruppnamn',
  send_as_newsletter: 'Ansvarsfriskrivning för nyhetsbrev',
  send_as_newsletter_desc: `Detta nyhetsbrev kommer att skickas som nyhetsbrev till alla aktiva affiliates (om en kampanj är vald, kommer endast affiliates i kampanjen att få ett e-postmeddelande). Detta kan inte ångras och fungerar endast om företaget har SMTP-inställning.`,
  primary_color: 'Primär färg',
  secondary_color: 'Sekundär färg',
  dark_mode: 'Mörkt läge',
  light_mode: 'Ljust läge',
  invoice_details: 'Fakturainformation',
  display_deposits_and_withdrawals: 'Visa insättningar och uttag som standard',
  display_accessibility_menu: 'Visa tillgänglighetsmeny',
  lifetime_affiliates: 'Livstidsaffiliates',
  customer_id: 'Kund-ID',
  leads: 'Leads',
  lead_created: 'Lead skapad',
  lead_updated: 'Lead uppdaterad',
  add_lead: 'Lägg till lead',
  create_lead: 'Skapa lead',
  edit_lead: 'Redigera lead',
  NEW: 'Ny',
  CONTACTED: 'Kontaktad',
  QUALIFIED: 'Kvalificerad',
  CONVERTED: 'Konverterad',
  CLOSED: 'Stängd',
  mlm_type: 'MLM Typ',
  brand_banner: 'Varumärkesbanner',
  add_to_dashboard: 'Lägg till på instrumentpanelen',
  remove_from_dashboard: 'Ta bort från instrumentpanelen',
  edit_new_creative: 'Redigera ny kreativ',
  remove_affiliate_withdrawal_request: 'Ta bort affiliate uttagsbegäran',
  remove_withdrawal_info_helper:
    'När du aktiverar alternativet tas den associerade uttagsknappen bort från affiliate-portalen',
  campaign_name_clone: 'Bekräfta kloning av {campaignTitle}',
  clone: 'Klona',
  campaign_clone_message:
    'Du håller på att klona denna kampanj. Observera följande: \n \u200B \n1. Affiliates kommer inte att klonas. \n2. Endast inställningar relaterade till spårning, allmän konfiguration och utbetalningar kommer att kopieras.\n3. Den klonade kampanjen kommer att pausas. \n \u200B \n Är du säker på att du vill fortsätta med kloningen?',
  automatically: 'Automatiskt',
  by_affiliate_request: 'Vid Affiliate Begäran',
  choose_how_to_handle_payouts:
    'Välj hur du vill hantera utbetalningarna. Automatiskt eller vid Affiliate Begäran.',
  period_type: 'Periodtyp',
  day_of_month: 'Dag i Månaden',
  day_of_week: 'Veckodag',
  allow_custom_amount_helper:
    'Slå på det här alternativet för att tillåta att affiliates manuellt anger en utbetalningssumma.',
  allow_attachment_helper:
    'Slå på det här alternativet för att tillåta bilaga vid utbetalningsbegäran.',
  allow_enter_amount: 'Tillåt Ange Belopp',
  allow_an_attachment: 'Tillåt Bilaga',
  form_editor: 'Formulärredigerare',
  form_preview: 'Formulärförhandsgranskning',
  lifetime_lifecycle: 'Livstids livscykel',
  lifetime_lifecycle_helper:
    'Lägger till utgångsdatum till livstidspartners. Gäller endast nya livstidspartners skapade efter ändringen',
  has_expiration_date: 'Har utgångsdatum',
  tiers_migration: 'Nivåer',
  bonus: 'Bonus',
  deposits_count: 'Insättningar',
  registrations_count: 'Registreringar',
  deposits_amount: 'Insättningsbelopp',
  threshold_reward_type: 'Belöningstyp för tröskel',
  campaign_payout_automation_bonuses: 'Kampanjutbetalning Automatisk bonus',
  recurring_bonus: 'Återkommande bonus',
  recurring_bonus_helper_text:
    'När på, beräknas bonusen månadsvis. När av, är det en engångsbonus per affiliate.',
  allow_api_keys_by_default: 'Tillåt API-nycklar som standard',
  allow_api_keys_helper:
    'Denna ändring kommer inte att påverka befintliga affilierade',
  campaign_payout_automation_desc_evaluation:
    '<strong>Prestationsutvärdering:</strong> I början av varje månad beräknar systemet affiliat-prestationer med hjälp av nyckelindikatorer såsom antal konverteringar, volym och antal konverteringar.',
  campaign_payout_automation_desc_performance_tiers:
    '<strong>Prestationsnivåer med tröskelvärden:</strong> Affiliates tilldelas specifika nivåer baserat på deras prestationer i förhållande till fördefinierade tröskelvärden.',
  campaign_payout_automation_desc_rewarding_performance:
    '<strong>Justeringar av utbetalningar:</strong> Affiliates som överträffar sina mål från föregående månad blir automatiskt uppflyttade till en högre utbetalningsnivå.',
  campaign_payout_automation_desc_personalized_payouts:
    '<strong>Bonusar för affiliates:</strong> Affiliates tjänar bonusar genom att uppnå specifika tröskelvärden. Om flera bonusar finns tillgängliga inom en enda automation, tilldelar systemet den bonus som är kopplad till det högsta uppnådda tröskelvärdet. Bonusbeloppet definieras i inställningarna för bonusutbetalningar.',
  estimated_original_price: 'Beräknat Ursprungligt Pris',
  conversion_rate: 'Konverteringsfrekvens',
  igaming_reports: 'IGaming-rapporter',
  clicks_to_registrations: 'Klick till Registreringar',
  registrations_to_deposits: 'Registreringar till Insättningar',
  clicks_to_deposits: 'Klick till Insättningar',
  fraud_score: 'Sannolikhet för bedrägeri',
  import_conversions: 'Importera konverteringar',
  update_conversions: 'Uppdatera konverteringar',
  if_commission_not_specified:
    'Om inte specificerat, justeras provisionerna automatiskt baserat på beloppet.',
  should_affiliates_change:
    'Ska förändringen av affiliate tillämpas på alla konton under användaren som för närvarande har samma tidigare affiliate?',
  min_amount: 'Minimibelopp'
};
